:root {
    /*Margins & Paddings*/
    --padding: 20px;
    --margin: 1em;
    --vertical-padding: 20px; /* must NOT be a percentage value */
    --vertical-margin: 2em;  /* must NOT be a percentage value */
    /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1100px;
    /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --header-min-height: 80px;
    /*Fonts*/
    --base-font-family: 'Quicksand', sans-serif;
    --alternative-font-family: 'Quicksand', sans-serif;
    --base-font-weight: 400;
    --alternative-font-weight: 700;
    /*Colors*/
    --base-font-color: #252525;
    --alternative-font-color: #4B6E68;
    --map-marker-color: 000;
}

@import "/css/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/css/basic_styling.css";
@import "/theme/intendit/css/modules/flex-grid.css";   
@import "/css/slick.css";
@import "/css/slick-theme.css";
@import "/css/menu.v1.2.css";
@import "/css/magnific-popup.css";
@import "/theme/intendit/css/modules/hoverboxes.css";   
@import "/fonts/Quicksand/all.css";  
 
/*
@import "/css/pikaday.css";
@import "/css/animate.css";
*/
 


.trygga {position: absolute; top: 20px; right: 20px; z-index:2; padding: 10px; background: rgba(255, 255, 255, 0.6); }
.trygga img {width: 100px; height:auto; }

/*------------------------------------------------------------------------------------------------ 
GENERAL ----------------------------------------------------------------------------------------*/
body {color: var(--base-font-color); font-family: var(--base-font-family); font-weight: var(--base-font-weight); line-height:1.3; 
 background: url('/thumbs/100x100r/theme/intendit/graphics/bg.png')  repeat fixed;  }

/*wrappers*/
main.wrapper {min-height: calc(100vh - var(--header-min-height)); position:relative; }  
.content-wrapper {padding: 40px 0 50px; position:relative; /*box-shadow: 0 0 50px 30px rgba(0, 0, 0, 0.2);*/} 
.container {width:100%; }
.container.xs {max-width:var(--breakpoint-xs);}
.container.sml {max-width:var(--breakpoint-s);}
.container.lrg {max-width: 1300px;}

/*basic styles*/
a, a:hover {color:var(--base-font-color); font-weight: var(--base-font-weight); text-decoration: none;}
p {margin:0.2em 0 1.2em;}
.content-wrapper p a {color:var(--alternative-font-color); font-weight:400; }
.content-wrapper p a:hover {text-decoration: underline;}
strong {font-weight:500;}
iframe {max-width:100%;}
 
/*------------------------------------------------------------------------------------------------ 
LISTS & TABLES ---------------------------------------------------------------------------------*/
.content-wrapper ul {list-style:none; padding: 0; margin: 0.2em 0 1em;}
.content-wrapper ul li:before {content: "-"; margin:0 0.5em 0 0; font-size:1.1em; font-weight:bold; }
/*.content-wrapper ul li {width: calc(50% - 2px); margin:0 2px 2px 0; float:left;}*/
/*.content-wrapper ul li:before {content: "\2714"; margin: 0 0.2em 0 0; font-size:1.2em; }*/

/*table*/
table {font-size:95%; width:100%;}
table tr {border-bottom: 1px solid #e4e4e4;}
table tr td {padding:5px 20px 5px 0; vertical-align: top; border:none; } 
.table-wrapper {display:table; width:100%; }  
.table-wrapper .fa-icon { height: 25px; width:100px; display:block; margin: 0 auto; background:#d0d0d0; fill:#fff; border-radius:40px;  }
.table-wrapper h3 {width:100%; margin-top: 10px; }

/*egna klasser*/
.spaltX2 {column-count: 2; column-gap: 50px; text-align:left !important;} /*Delar upp i 2 spalter*/
.shadow {box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);} 
.publishdate {color:#909090; font-size:95%; display:block; clear:both;  }
.record { border-bottom:1px solid #e4e4e4;}
/*.breadcrumbs {padding-bottom: 1em; font-size:0.9em; color:#606060; }
.breadcrumbs a { color:#606060; }*/

 
/*------------------------------------------------------------------------------------------------ 
IMAGE VIDEO FILELIST-----------------------------------------------------------------------------------------*/
img {max-width: 100%; height: auto;}
figure {margin:0; }
.flex > * > img {flex: 0 0 auto;}
.flex > * > img:not([class]) {width: 100%;}
/*artikelbild*/
figure.page-image {float:right; max-width:40%; height:auto; margin: 0 0 2em 2em;}
figure.page-image img {display:block; width:100%; height:auto; /*border-radius:50%; */  }
figure.page-image figcaption {font-style:italic; color:#606060;}  
/*video bakgrundsbild*/
.listing-video {background-size:cover; background-position:center center; min-height:200px; }
/*filelist*/ 
a.file {display:block; }

/*------------------------------------------------------------------------------------------------ 
BUTTONS & ICONS --------------------------------------------------------------------------------*/
.button.button, .readmore {margin: 0; padding: 6px 0 8px; display:block; width:100%; text-align: center; border:none; border-radius:2px; 
background: rgba(0, 0, 0, 0.1);  transition:background 0.5s ease-in 0s; }
.readmore {max-width:130px; background:#4B6E68; color:#fff; margin:  0px auto 0; border-radius: 30px; padding-top: 0.5em;} 
.button.button:hover, .readmore:hover { background:rgba(0, 0, 0, 0.3);} 
.contact-banner .button.button {background: rgba(255, 255, 255, 0.1);}  

body.tjanster  .readmore {margin: 10px 0 0; }


/*ikoner*/
.fa-icon {height:20px; width: 25px; vertical-align:middle !important; }
.round {background: rgba(0, 0, 0, 0.5); border-radius: 50%; display: inline-block; height: 40px; width: 40px; margin: 0 10px 10px 0; }
.round .fa-icon {fill:#fff; padding: 8px 0; height:40px; width: auto; display:block; margin: 0 auto; }
.round .fa-icon[aria-label="Icon depicting mobile"] {padding: 3px 0; }

footer .round {background: rgba(255, 255, 255, 0.2);}
footer svg {fill:#fff; }
.toptext .round {display:block; }
.box .round {display:block; margin:0 auto 1em; height: 80px; width: 80px;} 
.box .round .fa-icon { padding: 20px 0; height:80px; }

.listing-video .fa-icon {height: 40px; fill:#fff;}

/*------------------------------------------------------------------------------------------------ 
FONTS ----------------------------------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {letter-spacing:0; line-height:1.1; padding-bottom: 0.5em; margin: 0; font-family: var(--alternative-font-family); font-weight: var(--alternative-font-weight); }
h1{font-size: 2em; margin: 0 0 0.1em; /*text-transform:uppercase;*/}
h1.twelve {max-width:100%; }
h2{font-size: 1.65em;}
h3{font-size: 1.3em;}  
h4, h5, h6 {font-size: 1.3em; } 

h2.highlight {font-size:1.45em; padding: 0 0 0.5em; font-weight:700; color: var(--alternative-font-color); }

/*------------------------------------------------------------------------------------------------ 
TOPPEN ----------------------------------------------------------------------------------------*/
.toptext { position:absolute; right:10px; top:90px; z-index:11; display:none;  } /*display none by default*/
.imagetop {min-height:50vh; background-position: center top; background-size:cover; position: relative; }

/*------------------------------------------------------------------------------------------------ 
STARTSIDA ----------------------------------------------------------------------------------------*/
/*slick slider*/
.slick-wrapper {min-height:60vh; flex-wrap: wrap; position:relative; border-bottom: 10px solid #d0d0d0; /*width:100%; top: var(--header-min-height); z-index:-1;*/  }
.slick-track, .slick-substitute {display:flex; flex-direction:row;}
.slick-slider, .slick-substitute > div, .slick-slide {min-height:60vh; margin:0; }
.slick-slider, .slick-substitute, .slick-substitute > div { top:0; left:0; bottom:0; right:0; width:100%; z-index:0;}
.slick-slide, .slick-substitute > div {background-size:cover; background-position: center top; display:flex !important;}   
.slick {visibility: hidden;}  .slick-initialized {visibility: visible;}  .slick-list, .slick-track {height:100%;}

/*Slick title*/ 
.slick-title {width:100%; text-align:center; font-size: 1.5em; line-height:1.1em; padding: 30px 0; }
.slick-title h2 {font-size: 2em;}
 
/*Starttext - aktivera nedersta för starttext inuti slick-wrapper*/ 
.starttext {padding: 30px 0; width:100%;  text-align:center; z-index: 0; position:relative; }
.starttext h1 {margin: 1; font-size: 2em;}
.starttext p {margin-bottom:0; font-size: 1.1em; line-height:1.3; } 
/*.slick-slider, .slick-substitute > div { position:absolute; width:100%; height:100%;  top:0; left:0; z-index:-1; }*/

/*Slick arrows
.slick-prev, .slick-next {width:100px; height:100px; border:none;position:absolute; z-index:1; }  
.slick-prev {left:0px;} .slick-next {right:0px;}
.slick-prev:before, .slick-next:before { font-size:100px; color:#fff; opacity:0.9; font-family: Quicksand, sans-serif; }
.slick-prev:before {content:"<";} .slick-next:before {content:">";} */

/*Slick dots
.slick-dots {margin:0; bottom: 10px;  }
.slick-dots li button {background:#000; border-radius:50%; width:14px; height:14px; opacity:0.6; transition: opacity 0.5s ease-in 0s;  }
.slick-dots > .slick-active > button {opacity:1; }
.slick-dots li button:before {color: transparent; content: "";}*/

/*----------------------Boxar-------------------------------*/
.boxes {padding: 2em 0; position:relative; background:#fff;   }
.boxes > div > div {/*box-shadow: 0px 0px 0px 1px #e4e4e4;*/}
.boxes h3 { font-size:1.5em; color:#4B6E68; }

 .boxes h3 .fa-icon { height: 30px; width: 30px; fill:#4B6E68; }

.rutrot {padding: 40px 0; background:#d0d0d0; }
.rutrot h2 {text-transform: uppercase;  }

.rutrot ul {
    list-style:none; 
    padding: 0; 
    margin:15px 0;
 }
.rutrot ul li {background:#fff; color:#4B6E68; width: 100%; padding: 6px 20px;  border-radius: 3px; margin: 0 5px 5px 0;  font-weight:700; font-size:1.1em;  }
 



/* Niclas botten banner kod */
.bannerimg {
    height: 45vh;
    display: flex;
    justify-content: center;
    background-position: center;
    background-size: cover;
    position:relative;
    background-attachment: fixed;
    z-index:1;
}
.bannerimg:after{
    content:'';
    position:absolute;
    top:0;
    bottom:0;
    width:100%;
    height:100%;
    background:rgba(0,0,0,.35);
}
.bannerimg .container{
    max-width:800px;
    color:#fff;
    width:100%;
    margin:0 auto;
    z-index:2;
}
.whitebg{
    background:#fff;
    height: 45vh;
    display: flex;
    position:relative;
}
.blacktext .container{
    color:#000;
    
}

 


/*------------------------------------------------------------------------------------------------ 
CONTENT----------------------------------------------------------------------------------------*/
 
/*gallery*/
.image-gallery, .logotype-gallery {padding: 2em 0 0; margin-top:1em; border-top: 1px solid #d0d0d0; }
.gallery-image {box-shadow: 1px 1px 0 0 #e4e4e4;} .gallery-image:nth-child(5n + 5) {box-shadow: 0px 1px 0 0 #e4e4e4;}
.gallery-image a {display:block; }
.gallery-image img {padding:20px; display: block;}
.thumb-title {font-size:90%; padding: 0 20px 20px; display:none; }  

/*logo gallery*/
.logotype-image {min-height: 70px; } 
.logotype-image div, .logotype-image a {text-align:center; width: 100%; flex:0 0 auto; display:block;} 
.logotype-image img {height:auto;} .standing  {width:40%; } .squarish  {width:40%; } .landscape  {width:55%; }.longlandscape {width:70%; }.extralonglandscape {width:100%; }

/*magnific styling*/ 
.mfp-bg {background: #000; opacity:0.9;}  .mfp-arrow {top:40%; } 
.mfp-arrow, .mfp-arrow:before, .mfp-arrow-left:before, .mfp-arrow-right:before,.mfp-arrow:after, .mfp-arrow-left:after, .mfp-arrow-right:after {border:none ; margin-left:0; margin-right:0;  }
.mfp-arrow-left:before {content:"<"; } .mfp-arrow-right:before {content:">"; }
.mfp-arrow-left:before, .mfp-arrow-right:before {height:100px; width:100px; color:#fff; font-size:5.5em; font-family: 'Quicksand', sans-serif; opacity:0.9;}
.mfp-arrow-left {margin-left:20px; }  .mfp-arrow-right {margin-right:20px; } 
button.mfp-close { font-size: 4em; margin:0 -2px 0 0; opacity: 0.9; font-family: Quicksand; }
/*.mfp-title a, .mfp-title a:hover {color:#fff;}
.mfp-title .fa-icon {height:16px; display: none; vertical-align: bottom !important;}*/

.mfp-bottom-bar {display: none;}

/*------------------------------------------------------------------------------------------------ 
FOOTER & KONTAKT--------------------------------------------------------------------------------*/
.contact-banner { background: #4B6E68; padding:50px 0; color:#fff; position:relative; }
.boltforms-row input, .boltforms-row textarea {width:100%; color:#606060; margin:0;  } 
.boltform .flex.margin > * {margin: 0 calc(0.5 * var(--margin)) calc(0.5 * var(--vertical-margin)); }
.contact-banner .boltforms-row label, .contact-banner .boltforms-row button, .contact-banner .boltforms-row button:hover { font-weight:400;  margin-bottom: 0.3em; color:#fff; }
.contact-form .boltforms-row label, .contact-form .boltforms-row button, .contact-form .boltforms-row button:hover { font-weight: var(--base-font-weight);   margin-bottom: 0.3em;  color: var(--base-font-color); }
 
/*karta*/
.map-canvas {height: 35vh; margin-top:2em;}
.map-canvas img {max-width: none;}
.markerLabels{ overflow:visible !important;}
.fa-map-marker{ background: url('data:image/svg+xml;utf8,%3Csvg%20fill%3D%22%23var(--map-marker-color)%22%20width%3D%221792%22%20height%3D%221792%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1152%20640q0-106-75-181t-181-75-181%2075-75%20181%2075%20181%20181%2075%20181-75%2075-181zm256%200q0%20109-33%20179l-364%20774q-16%2033-47.5%2052t-67.5%2019-67.5-19-46.5-52l-365-774q-33-70-33-179%200-212%20150-362t362-150%20362%20150%20150%20362z%22%2F%3E%3C%2Fsvg%3E');
background-size: cover; padding: 10px; background-position: center; }

iframe {
    max-width: 100%;
    width: 100%;
    height: 30vh;
    border: none;
}


/*footer*/
footer {padding: 50px 0 120px; width:100%; background:#000; position:relative; }
footer, footer a, footer a:hover {color:#b0b0b0; }
footer h4 { padding: 0 0 5px; font-size:1.3em; } 
/*sticky*/
html.sticky {height:100%;} .sticky body {display:flex; flex-direction:column; min-height:100%;}
.sticky main.wrapper {flex: 1 0 auto; min-height:1em; } .sticky header, .sticky footer {flex: 0 0 auto; }

/*intendit*/
.created-by {text-align: center; padding: 8px 0; background:#101010; position:relative;}
.created-by p, .created-by a {color:#707070; font-size: 0.9em; letter-spacing: 0.5pt; margin: 0;}

/*not found*/
.pagenotfound {min-height: 100vh; position:relative; text-align:center; font-size:1.1em; background:#fff; background-image:none; }
.pagenotfound .logo img {max-height: 70px; margin: 0 auto; } .pagenotfound .logo   {text-align:center; margin: 0 auto 20px;  }
.pagenotfound ul {list-style-type:none; padding: 0; margin: 0.5em auto; width:100%; max-width: 550px; }
.pagenotfound li a { border-bottom: 1px dashed #d0d0d0; display: inline-block;  padding: 10px 110px; }


/*------------------------------------------------------------------------------------------------ 
HEADER ----------------------------------------------------------------------------------------*/
header {background:#4B6E68;width:100%; top:0;  z-index:10; min-height:var(--header-min-height); font-family: var(--base-font-family);}
header > div {min-height:inherit;} 
body.scrolled header {/*transition:background 0.5s ease-in 0s;*/}

/*Logotype*/
.logo { padding:10px 15px; box-sizing:border-box; max-width: calc(100% - 60px);} 
.logo a, .logo a:hover {padding:0; display:block; } 
.logo a img {max-height: 55px; width:auto; display:block; max-width:100%;  }
.logo a span {display:block; max-width:100%; font-size:2em; font-family: var(--alternative-font-family); font-weight: var(--alternative-font-weight); line-height: 1em;}

/*Standard meny + hovers*/
nav {background:inherit; } 
header nav ul.menu > li {padding: 0 10px; margin:0; border-right:1px solid #e4e4e4;}
nav ul.menu > li:last-child {border:none; }
nav ul.menu li a { padding: 4px 5px; margin:0; letter-spacing:0; color:#fff; /*transition:color 0.5s ease-in 0s;*/}
nav ul.menu li a:hover, nav ul.menu li.active a {color:#fff; }
nav ul.menu li.dropdown > a:after { content: ">"; transform: rotate(90deg); vertical-align: middle; display: inline-block; font-family: 'Quicksand', sans-serif;  font-size: 1em; margin: 0 0 0 5px;  }
nav .menu .dropdown ul  {border:none; padding: 10px 0; left:20px; }
nav ul.menu > li:last-child > ul { left: auto;right: 0; text-align:right; }


/*languages
li.localeswitcher  {display:none;} 
.localeswitcher.active {display:none;}
header nav ul.menu > li.localeswitcher { border:none; }
nav ul.menu li.localeswitcher a { padding: 0; margin: 0 0 -8px;}
li.localeswitcher img {border-radius:50%; display:block; width: 30px; height:auto; }*/


/*Mobile menu - fixed header*/
@media (max-width: var(--breakpoint-menu)){
header { position:fixed; min-height:70px; box-shadow:none; flex-wrap:nowrap;  } /*background på header bar*/
header.flex {flex-wrap: nowrap; }
body {border-top: 70px solid transparent;}   
nav ul.menu.show { max-height:calc(100vh - var(--fixed-header-height)); padding: 10px 0; background:#4B6E68}  /*räknar höjd - fixed header*/
nav ul.menu li, .mobile-menu nav ul.menu li a, .mobile-menu nav .menu .dropdown ul {border:none; background:none; }
nav .menu .dropdown ul {padding:0; left:0px;} 
nav ul.menu li.dropdown > a:after {display:none; }
    
/*logo*/   
.logo { padding: 15px 10px 5px 10px; align-items: flex-start; }
.logo a img { max-height: 50px; }
    
/*menu icon*/
nav #menu-icon {align-self: flex-start; padding: 20px 15px 20px 0;}
nav #menu-icon span, nav #menu-icon:before, nav #menu-icon:after { border-radius:3px; color:#fff;}
}


/*------------------------------------------------------------------------------------------------ 
MEDIA QUERIES ----------------------------------------------------------------------------------*/

/*----------------------------Allt under 1300px----------------------------*/
@media (max-width: 1300px) 
{  
/* Standard meny - tight it up*/ 
nav ul.menu li {padding:0 5px; }      
nav ul.menu li a {}   
    
/*Container*/
.container.lrg {width: 96%;}   
header.container.lrg {width: 100%;}   
    
}
   
/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: var(--breakpoint-m))
{
/*flex*/    
.flex.m-no-lr-margin > * {margin-left:0 !important; margin-right:0 !important; }
.flex.m-no-padding > * {padding:0 !important;}
   
    
/*Startsida*/ 
.starttext {}
.starttext h1 {}
.starttext p {} 
    
/*Containers*/
.container {width: 96%;}  
header.container {width: 100%;}  
    
/*slick arrows*/   
.slick-prev, .slick-next {height: 60px; width: 60px;}
.slick-prev:before, .slick-next:before { font-size: 60px;}   
}

/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
.trygga img {
    height: auto;
    width: 140px;
}
/*Startsida*/ 
.starttext {}
.starttext h1 { }
.starttext p {} 
    
/*Fontsize*/ 
body {font-size: 0.95em; }
    
/*Slider & topbild height */ 
.slick-wrapper {position:relative; top: auto; }
.slick-wrapper, .slick-slider, .slick-substitute > div, .slick-slide {min-height:55vw;}
.imagetop {min-height:45vw;}
 
.slick-wrapper + .starttext {margin-top:0;}
.boxes {margin-top:0;}    
    
/*gallerithumbs*/
.gallery-image.gallery-image {box-shadow: none;}
.gallery-image img {padding: 0.8em;}    
 
/*Bild*/
figure.page-image {margin: 0 0 1.3em 1.3em;}
     
/*Footer & Contact*/   
footer .s-twelve {border-bottom: 1px solid; margin: 0 auto !important; max-width: 400px; padding: 20px 0 10px; text-align: center; }
footer .s-twelve:last-child {border-bottom: none; }   
    
/*Not found*/
.pagenotfound li a {padding: 10px 20px; width: 100%;}
 
/*Containers*/
.container.sml {width: 96%;} 
    
/*flex margin & paddings*/    
.margin > .twelve, .margin > .m-twelve, .margin > .s-twelve  {margin: 0 auto var(--margin) !important; flex-grow:1 !important;}    
.flex.s-no-padding > * {padding:0 !important;}
 
}

footer a {
    display:block;
    padding: 0.3rem 0;
    }
.contactpadding {
    padding: 1rem 0;

}


/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{   
    
    .trygga {display:none; } 
    
    
    .readmore {margin: 10px 0 0; } 
    
/*font*/    
h1 {font-size:2em;} 

/*main*/
main.wrapper {min-height: 10px; }  
.content-wrapper {padding: 30px 0;}
    
/*imagetop*/  
.imagetop {min-height:50vw;}
    
    
/*Startsida*/ 
.starttext {}
.starttext h1 {font-size:1.7em !important;}
.starttext p {} 
      
/*image*/
figure.page-image {margin: 0 0 10px 10px; padding:0; border:none;  }   
    
/*sidfot*/
footer  {font-size: 1.1em; line-height: 1.5; word-spacing: 2pt; padding: 30px 0 40px; }     
     
/*spalt*/    
.spaltX2 {-webkit-column-count: 1; -moz-column-count: 1; column-count: 1; -webkit-column-gap: 0px; -moz-column-gap: 0px; column-gap: 0px; text-align:none;} 
   
/*Kontaktbanner*/   
.contact-banner {padding: 30px 0;}

/*map*/
.map-canvas {width:100%; max-height:20vh;} 
 
    
/*Containers*/
.container.xs {width: 96%;}  
    
/*flex margin & paddings*/ 
.margin > .xs-twelve {margin: 0 auto var(--margin) !important; flex-grow:1 !important;}
.flex.xs-no-padding > * {padding:0 !important;} 

/*logo*/
.logo a span {overflow-wrap: break-word; word-wrap: break-word;word-break: break-all;word-break: break-word; hyphens: auto;} 
.logo {padding:0; }    .logo a img {padding:15px 10px 5px 10px;  max-height: 65px; }   .logo a span {padding:10px; font-size: 1.7em;}  
 
a[href^="mailto"] .fa-icon {height: 18px; vertical-align: sub !important;}
   
}

@media (max-width: 375px)
{    
 h1, h2, h3, h4, h5, h6 { hyphens: auto;}
  
/*font*/    
h1 {font-size:1.8em !important; }  

/*start*/
.slick-wrapper .starttext p {display:none;} 
    
/*bild*/
figure.page-image { width:100%; max-width:100%; margin: 0 auto 1em; float:none; }     
}

 

/*------------------------------------------------------Make FB widget responsive-------------------------------------------------------*/
.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget iframe[style], .twitter-timeline {width: 100% !important;}
